import React, { useState, useEffect, useRef, useCallback, useMemo} from 'react';
import { useLocation, useNavigate, useParams  } from 'react-router-dom';
import '../css/Page3.css';
// import negotiationScenarios from '../data/negotiationScenarios_ko.json';
import negotiationScenarios from '../data/negotiationScenarios_en.json';
import Popup from './Popup';

const Page3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {scenarioId } = useParams();
  const product = location.state?.product;
  const [lastPrice, setLastPrice] = useState(Infinity);
  const [currentStep, setCurrentStep] = useState('1');
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [priceInputCount, setPriceInputCount] = useState(0);

  const [options, setOptions] = useState([]);
  const [isEnded, setIsEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [negotiationScenario, setNegotiationScenario] = useState(null);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const chatFrameRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const timerRef = useRef(null);

  const [visibleMessages, setVisibleMessages] = useState([]);
  const [messageStates, setMessageStates] = useState({});

  const [lastInputValue, setLastInputValue] = useState(null);
  const [calculatedPrice, setCalculatedPrice] = useState(null);

  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [twoPercentCount, setTwoPercentCount] = useState(0);
  
  const productInfo = {
    1: { name: 'Electric Radiato', company: 'Shinil', model_id: '10545082', cost: '1,000', img: 'productA.jpg'},
    2: { name: 'Factory AC', company: 'Airex', model_id: 'CD-600', cost: '1,000', img: 'productB.jpg' },
    3: { name: 'Industrial Fan Set', company: 'Smato', model_id: 'SMP35', cost: '500', img: 'productC.jpg' },
    4: { name: 'Ventilator', company: 'InnoTech', model_id: 'TWP-150CG', cost: '90', img: 'productD.jpg' },
  };

  const currentProduct = productInfo[scenarioId];

  const openPopup = useCallback(() => setIsPopupOpen(true), []);
  const closePopup = useCallback(() => setIsPopupOpen(false), []);

  useEffect(() => {
    if (lastInputValue !== null && currentProduct) {
      const originalPrice = parseFloat(currentProduct.cost.replace(/,/g, ''));
      let newCalculatedPrice;

      if (scenarioId === '1' || scenarioId === '2') {
        newCalculatedPrice = originalPrice * (1 - lastInputValue / 100);
      } else {
        newCalculatedPrice = lastInputValue;
      }

      setCalculatedPrice(Math.round(newCalculatedPrice).toLocaleString());
    }
  }, [lastInputValue, currentProduct, scenarioId]);

  const handleRestart = () => {
    navigate('/');
  };

  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
  };

  useEffect(() => {
    const loadScenario = () => {
      try {
        console.log("Loading scenario data...");
        console.log("scenarioId:", scenarioId);
        console.log("All scenarios:", negotiationScenarios);
        const selectedScenario = negotiationScenarios[`scenario${scenarioId}`];
        console.log("Selected scenario:", selectedScenario);
        if (selectedScenario) {
          setNegotiationScenario(selectedScenario);
          setIsLoading(false);
        } else {
          console.error("Scenario not found");
          setError("The selected scenario cannot be found.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error loading scenario:", error);
        setError("An error occurred while loading the scenario.");
        setIsLoading(false);
      }
    };

    if (scenarioId) {
      loadScenario();
    }
  }, [scenarioId]);

  useEffect(() => {
    let timer;
    if (messages.length > visibleMessages.length) {
      timer = setTimeout(() => {
        setVisibleMessages(prev => [...prev, messages[prev.length]]);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [messages, visibleMessages]);

  const replacePlaceholders = useCallback((message, currentProduct, lastInputValue, calculatedPrice) => {
    if (!currentProduct) return message;
    
    console.log('currentProduct : ', currentProduct);
    console.log('lastInputValue : ', lastInputValue);
    console.log('calculatedPrice : ', calculatedPrice);
    console.log('message : ', message);

    const productInfoHTML = `
      <div class="product-info">
        <table class="product-info-table">
          <tr><th>Product</th><td class="product-name">${currentProduct.name || ''}</td></tr>
          <tr><th>Company</th><td class="product-company">${currentProduct.company || ''}</td></tr>
          <tr><th>Model</th><td class="product-model">${currentProduct.model_id || ''}</td></tr>
          <tr><th>Price</th><td class="product-cost">${currentProduct.cost || ''} USD</td></tr>
        </table>
        <img class="product-image" src="/imgs/${currentProduct.img}" alt="상품 이미지">
      </div>
    `;
  
    return message
      .replace(/① (Product|상품명).*?⑤ (Price|실례가):.*?(?=\n|$)/s, productInfoHTML)
      // .replace(/①\s*상품명.*?⑤\s*가격:.*?(?=\n|$)/s, productInfoHTML)
      .replace(/\[품목명\]/g, currentProduct.name || '')
      .replace(/\[제조사\]/g, currentProduct.company || '')
      .replace(/\[모델명\]/g, currentProduct.model_id || '')
      .replace(/\[실례가\]/g, currentProduct.cost || '')
      .replace(/\[이미지\]/g, currentProduct.img || '')
      .replace(/\[입력값\]/g, lastInputValue !== null && lastInputValue !== undefined ? `${lastInputValue}` : '[입력값]')
      .replace(/\[계산된 금액\]/g, calculatedPrice !== null && calculatedPrice !== undefined ? `${calculatedPrice}` : '[계산된 금액]')
      .replace(/\[evaluationGrades\]/g, currentProduct.evaluationGrades || '')
      .replace(/\[vocScores\]/g, currentProduct.vocScores || '')
      .replace(/\[durations\]/g, currentProduct.durations || '')
      .replace(/\[revenues\]/g, currentProduct.revenues || '')
      .replace(/\[서비스 이용 안내 및 주의 사항\]/g, '{{OPEN_POPUP}}');
  }, []);

  const memoizedReplacePlaceholders = useMemo(() => 
    (message) => replacePlaceholders(message, currentProduct, lastInputValue, calculatedPrice),
    [replacePlaceholders, currentProduct, lastInputValue, calculatedPrice]
  );

  const startTimer = useCallback((duration) => {
    setTimeRemaining(duration);
    timerRef.current = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTime - 1;
      });
    }, 3000);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const checkPriceRange = useCallback((price, ranges) => {
    if (!ranges || !Array.isArray(ranges)) {
      console.error("Invalid or missing price ranges");
      return null;
    }
    
    for (const range of ranges) {
      let min, max;
      
      if (typeof range.min === 'string' && range.min.includes('lastPrice')) {
        const [, , value] = range.min.match(/(lastPrice)([+-]\d+)?/) || [];
        min = lastPrice + (value ? Number(value) : 0);
      } else {
        min = Number(range.min);
      }
      
      if (typeof range.max === 'string' && range.max.includes('lastPrice')) {
        const [, , value] = range.max.match(/(lastPrice)([+-]\d+)?/) || [];
        max = lastPrice + (value ? Number(value) : 0);
      } else {
        max = Number(range.max);
      }
      
      console.log(`Checking range: ${min} <= ${price} <= ${max}`);
      
      if (price >= min && price <= max) {
        return range;
      }
    }
    return null;
  }, [lastPrice]);
  
  // const processStep = useCallback((stepId) => {
  //   console.log("Processing step:", stepId);
    
  //   if (!negotiationScenario || !negotiationScenario.scenario) {
  //     console.error("Negotiation scenario is not loaded properly");
  //     setError("The scenario data did not load correctly.");
  //     return;
  //   }
  
  //   const step = negotiationScenario.scenario[stepId];
  //   if (!step) {
  //     console.log("Step not found, checking for exit condition");
  //     if (stepId === 'exit') {
  //       setIsEnded(true);
  //       setMessages(prev => [...prev, { text: "The negotiation has ended. Thank you.", isBot: true }]);
  //       setOptions([]);
  //     }
  //     return;
  //   }
  
  //   let message = replacePlaceholders(step.message, product);
  
  //   setTimeout(() => {
  //     if (stepId === '10' && scenarioId === '3') {
  //       setMessages(prev => [...prev, { text: message, isBot: true }]);
  //       setIsEnded(true);
  //       setOptions([]);
  //     } else {
  //       setMessages(prev => [...prev, { text: message, isBot: true }]);
  //       setCurrentStep(stepId);
    
  //       if (step.timerStart) {
  //         startTimer(step.timerDuration);
  //       }
    
  //       if (step.options) {
  //         setOptions(step.options);
  //       } else {
  //         setOptions([]);
  //       }
        
  //       if (step.input || step.priceCheck) {
  //         // 입력 필드를 표시하기 위한 로직
  //       } else if (step.next && stepId !== '2') {
  //         setTimeout(() => {
  //           processStep(step.next);
  //         }, 3000);
  //       }
  //     }
  //   }, 500);
  // }, [product, replacePlaceholders, negotiationScenario, startTimer, scenarioId]);

  const processStep = useCallback((stepId) => {
    console.log("Processing step:", stepId);
    
    if (!negotiationScenario || !negotiationScenario.scenario) {
      console.error("Negotiation scenario is not loaded properly");
      setError("The scenario data did not load correctly.");
      return;
    }
  
    const step = negotiationScenario.scenario[stepId];
    if (!step) {
      console.log("Step not found, checking for exit condition");
      if (stepId === 'exit') {
        setIsEnded(true);
        setMessages(prev => [...prev, { text: "The negotiation has ended. Thank you.", isBot: true }]);
        setOptions([]);
      }
      return;
    }
  
    let message = replacePlaceholders(step.message, product);
  
    setTimeout(() => {
      if (stepId === '10' && scenarioId === '3') {
        if (message && message.trim() !== '') {
          setMessages(prev => [...prev, { text: message, isBot: true }]);
        }
        setIsEnded(true);
        setOptions([]);
      } else {
        if (message && message.trim() !== '') {
          setMessages(prev => [...prev, { text: message, isBot: true }]);
        }
        setCurrentStep(stepId);
    
        if (step.timerStart) {
          startTimer(step.timerDuration);
        }
    
        if (step.options) {
          setOptions(step.options);
        } else {
          setOptions([]);
        }
        
        if (step.input || step.priceCheck) {
          // 입력 필드를 표시하기 위한 로직
        } else if (step.next && stepId !== '2') {
          setTimeout(() => {
            processStep(step.next);
          }, 3000);
        }
      }
    }, 500);
  }, [product, replacePlaceholders, negotiationScenario, startTimer, scenarioId]);
    
  useEffect(() => {
    if (negotiationScenario && negotiationScenario.scenario && currentStep === '1') {
      console.log("Starting negotiation process");
      processStep('1');
    }
  }, [negotiationScenario, processStep, currentStep]);

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const handleOptionClick = (option) => {
    setMessages(prev => [...prev, { text: option.text, isBot: false }]);
    if (option.next === 'exit') {
      setTimeout(() => {
        const exitMessage = option.exitMessage || "The negotiation has ended. Thank you.";
        setMessages(prev => [...prev, { text: exitMessage, isBot: true }]);
        setIsEnded(true);
        setOptions([]);
      }, 3000);
    } else {
      setOptions([]);
      processStep(option.next);
    }
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;
  
    const inputValue = parseFloat(userInput);
  
    if ((scenarioId === '1' || scenarioId === '2') && !Number.isInteger(inputValue)) {
      setErrorMessage('Decimal input is not allowed.');
      setIsErrorPopupOpen(true);
      return;
    }
  
    console.log("Current step:", currentStep);
    console.log("Input value:", inputValue);
  
    const step = negotiationScenario.scenario[currentStep];
    console.log("step:", step);
  
    if (step && step.priceCheck) {
      if (scenarioId === '1' && inputValue === 2) {
        setTwoPercentCount(prevCount => prevCount + 1);
      
        if (twoPercentCount >= 2) {
          setErrorMessage('2% discount rate has been entered more than 3 times. The negotiation cannot proceed further.');
          setIsErrorPopupOpen(true);
          setIsEnded(true);
          return;
        }
      }

      if (scenarioId === '3' && inputValue >= lastPrice) {
        setErrorMessage("You must propose a price lower than the previous one.");
        setIsErrorPopupOpen(true);
        return;
      }
  
      const range = checkPriceRange(inputValue, step.priceRanges);
      if (range) {
        if (range.errorMessage) {
          setErrorMessage(range.errorMessage);
          setIsErrorPopupOpen(true);
          return;
        }
        setLastPrice(inputValue);
        setPriceInputCount(prevCount => prevCount + 1);
  
        const originalPrice = parseFloat(currentProduct.cost.replace(/,/g, ''));
        const calculatedPrice = originalPrice * (1 - inputValue / 100);
        setCalculatedPrice(Math.round(calculatedPrice).toLocaleString());
      } else {
        setErrorMessage("The entered value is not within a valid range.");
        setIsErrorPopupOpen(true);
        return;
      }
    }
    setLastInputValue(inputValue);
    setMessages(prev => [...prev, { text: userInput, isBot: false }]);
    setUserInput('');
  
    setTimeout(() => {
      if (step.priceCheck) {
        const range = checkPriceRange(inputValue, step.priceRanges);
        if (range) {
          processStep(range.next);
        } else {
          setMessages(prev => [...prev, { text: "The value you entered is invalid. Please try again.", isBot: true }]);
        }
      } else if (step.next) {
        processStep(step.next);
      }
    }, 3000);
  
    if (scenarioId === '3') {
      if (priceInputCount >= 2 || timeRemaining <= 0) {
        setTimeout(() => {
          processStep('10');
        }, 3000);
      }
    } else if (scenarioId === '4') {
      if (priceInputCount >= 2) {
        setTimeout(() => {
          processStep('7');
        }, 3000);
      }
    }
  };

  const renderMessage = useCallback((message, index) => {
    if (!message || !message.text) {
      console.error('Invalid message:', message);
      return null;
    }

    const parts = message.text.split('{{OPEN_POPUP}}');

    if (!message.isBot) {
      return <div className="user-message-text">{message.text}</div>;
    }

    return (
      <div className="message-text">
        {!messageStates[index]?.isComplete && (
          <img src="/imgs/typing-indicator.gif" alt="Typing" className="typing-gif" />
        )}
        {messageStates[index]?.isComplete && (
          <>
            {parts.map((part, partIndex) => (
              <React.Fragment key={partIndex}>
                <div dangerouslySetInnerHTML={{ __html: memoizedReplacePlaceholders(part) }} />
              </React.Fragment>
            ))}
            {parts.length > 1 && (
              <div className="info-link-container">
                <span className="info-link">
                  [Service Usage Guidelines and Precautions]<br></br>
                <div style={{'textAlign': 'left','color':'black'}}>
                <p>Please note the following points regarding the negotiation</p>
                <p>1. The negotiation will end 12 hours after it begins.</p>
                <p>2. We request that the person responsible for negotiations with us handle the process.</p>
                <p>3. Additional price adjustments may be requested during the negotiation.</p>
                <p>4. Negotiation authority cannot be transferred to a third party.</p>
                <p>5. The results of the negotiation must be kept confidential.</p>
                <p>6. Once the negotiation ends, cancellation or changes are not allowed without special reasons.</p>
                <p>7. For any other questions, please contact us at</p>
                <p>[Help Desk: 1588-1234, Email: help@ktcommerce.com]</p>
              </div>

            {/* [서비스 이용안내 및 주의사항]<br></br>
            <div style={{'textAlign': 'left', 'color':'black'}}>
              <p>협상과 관련하여 다음 사항을 유의해 주시기 바랍니다.</p>
              <p>1. 협상은 시작 후 12시간 후에 종료됩니다.</p>
              <p>2. 당사와의 협상 담당자가 직접 협상을 진행해 주시기 바랍니다.</p>
              <p>3. 협상 중 추가적인 가격 조정 요청이 있을 수 있습니다.</p>
              <p>4. 협상 권한을 제3자에게 양도할 수 없습니다.</p>
              <p>5. 협상 결과는 기밀로 유지되어야 합니다.</p>
              <p>6. 협상 종료 후에는 특별한 사유 없이 취소나 변경이 불가능합니다.</p>
              <p>7. 기타 문의사항은 아래로 연락 주시기 바랍니다.</p>
              <p>[고객센터: 1588-1234, 이메일: help@ktcommerce.com]</p>
            </div> */}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );
  }, [memoizedReplacePlaceholders, messageStates]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastIndex = messages.length - 1;
      setMessageStates(prev => ({
        ...prev,
        [lastIndex]: { isComplete: false }
      }));
      setTimeout(() => {
        setMessageStates(prev => ({
          ...prev,
          [lastIndex]: { isComplete: true }
        }));
      }, 3000);
    }
  }, [messages]);

  if (isLoading) {
    return <div className="container"><div className="msger">Loading...</div></div>;
  }

  if (error) {
    return <div className="container"><div className="msger">Error: {error}</div></div>;
  }

  if (!product || !negotiationScenario) {
    return <div className="container"><div className="msger">Product information or scenario cannot be found.</div></div>;
  }

  console.log("Rendering Page3 with product:", product, "and scenario:", negotiationScenario);

  return (
    <div className="container">
      <div className="msger">
        <header className='msger-header'>
          <div className='msger-header-title'>
            <h1>KT commerce Intelligence Portal</h1>
            <span className="beta-tag">Beta</span>
          </div>
          <button className="restart-button" onClick={handleRestart}>Restart Nego-Wiz</button>
        </header>
        
        {timeRemaining !== null && (
          <div className="timer">
            Timer: {formatTime(timeRemaining)}
          </div>
        )}

        <main className='msger-chat-frame' ref={chatFrameRef}>
          {messages.map((message, index) => (
            <div key={index} className={`chat-message ${message.isBot ? 'kt-message' : 'user-message'}`}>
              {message.isBot && (
                <div className="kt-info">
                  <img src="/imgs/ktc-logo-02.svg" alt="KT Logo" className="kt-logo" />
                  <p>Intelligence portal</p>
                </div>
              )}
              <div className={`message-content ${message.isBot ? '' : 'user-message-content'}`}>
                {renderMessage(message, index)}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </main>

        {!isEnded && (
          <div className="chat-input-area">
            {options.length > 0 && (
              <div className="options-container">
                {options.map((option, index) => (
                  <button 
                    key={index} 
                    className="option-button" 
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.text}
                  </button>
                ))}
              </div>
            )}
           
            {(negotiationScenario.scenario[currentStep]?.input || negotiationScenario.scenario[currentStep]?.priceCheck) && (
              <form onSubmit={handleInputSubmit} className="chat-input-form">
                <div className="input-container">
                  <span className="input-symbol">
                    {scenarioId === '1' || scenarioId === '2' ? '%' : '$'}
                  </span>
                  <input
                    className="chat-input"
                    type="tel"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={(e) => {
                      if (scenarioId === '1' || scenarioId === '2') {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }
                    }}
                    placeholder={
                      scenarioId === '1' || scenarioId === '2'
                        ? 'Please enter a discount rate (integers only)'
                        : scenarioId === '3' || scenarioId === '4'
                        ? 'Please enter a price'
                        : 'Please enter a price or discount rate'
                    }
                  />
                </div>
                <button type="submit" className="chat-submit-button">Submit</button>
              </form>
            )}

          </div>
        )}

        <footer className="msger-footer">
          © 2024 KT commerce Intelligence Portal. All rights reserved.
        </footer>

        <Popup isOpen={isPopupOpen} onClose={closePopup}>
          <h2>Important Notes</h2>
          <div style={{'textAlign': 'left'}}>
            <p>Please note the following points regarding the negotiation</p><br></br>
            <p>1. The negotiation will end 12 hours after it begins.</p><br></br>
            <p>2. We request that the person responsible for negotiations with us handle the process.</p><br></br>
            <p>3. Additional price adjustments may be requested during the negotiation.</p><br></br>
            <p>4. Negotiation authority cannot be transferred to a third party.</p><br></br>
            <p>5. The results of the negotiation must be kept confidential.</p><br></br>
            <p>6. Once the negotiation ends, cancellation or changes are not allowed without special reasons.</p><br></br>
            <p>7. For any other questions, please contact us at</p><br></br>
            <p>[Help Desk: 1588-1234, Email: help@ktcommerce.com]</p><br></br>
          </div>

          {/* <div style={{'textAlign': 'left', 'color':'black'}}>
              <p>협상과 관련하여 다음 사항을 유의해 주시기 바랍니다.</p>
              <p>1. 협상은 시작 후 12시간 후에 종료됩니다.</p>
              <p>2. 당사와의 협상 담당자가 직접 협상을 진행해 주시기 바랍니다.</p>
              <p>3. 협상 중 추가적인 가격 조정 요청이 있을 수 있습니다.</p>
              <p>4. 협상 권한을 제3자에게 양도할 수 없습니다.</p>
              <p>5. 협상 결과는 기밀로 유지되어야 합니다.</p>
              <p>6. 협상 종료 후에는 특별한 사유 없이 취소나 변경이 불가능합니다.</p>
              <p>7. 기타 문의사항은 아래로 연락 주시기 바랍니다.</p>
              <p>[고객센터: 1588-1234, 이메일: help@ktcommerce.com]</p>
            </div> */}
        </Popup>

        <Popup isOpen={isErrorPopupOpen} onClose={closeErrorPopup}>
        {/* <h2>Invalid Input</h2> */}
        <h2>Check Revised Price</h2>
        <p>{errorMessage}</p>
        </Popup>
      </div>
    </div>
  );
};

export default Page3;